import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { take } from 'rxjs/operators';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEY } from '../constants/storage.constant';

/**
 * this function addresses the flashing translation label for ngx-translate
 */
export function TranslationFactory(
  translateService: TranslateService,
  injector: Injector
): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );

      // 先看有没有已保存的语言设置
      Preferences.get({ key: STORAGE_KEY.LANGUAGE }).then(lang => {
        if (lang && lang.value) {
          locationInitialized.then(() => {
            translateService
              .use(lang.value)
              .pipe(take(1))
              .subscribe(
                (res) => { },
                (err) => console.log(err),
                () => resolve(null)
              );
          });
        } else {
          // 再看设备的语言设置
          Device.getLanguageCode().then(info => {
            const selectedLang = (info && (info.value as string).indexOf('zh') > -1) ? 'cn' : 'en';
            Preferences.set({ key: STORAGE_KEY.LANGUAGE, value: selectedLang });
            locationInitialized.then(() => {
              translateService
                .use(selectedLang)
                .pipe(take(1))
                .subscribe(
                  (res) => { },
                  (err) => console.log(err),
                  () => resolve(null)
                );
            });
          });
        }
      });
    });
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
