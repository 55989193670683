export const API = {
  PREFIX: '/api/v2/',
  APP_INFO: 'public/app-info',
  TRACE_ACTION: 'public/customer-action',
  CONTACT_US: 'public/contact-us',
  APP_MESSAGES: 'public/getMessages/0/500',
  PAYER_LIST: 'public/getPayers',
  LOGIN: 'public/login',
  CHECK_EMAIL: 'public/verify-email',
  REFRESH_TOKEN: 'refresh',
  SIGNUP_GET_VERIFICATION_CODE: 'public/verify-code',
  VERIFY_REFERRAL_CODE: 'public/verify-refer-code',
  SIGNUP: 'public/signup',
  LOGOUT: 'logout',
  FORGET_PASSWORD_VERIFYING: 'public/reset-password/verifying',
  FORGET_PASSWORD_GET_VERIFICATION_CODE: 'public/reset-password/verify-code',
  FORGET_PASSWORD_VERIFY_CODE_FIRST: 'public/verifying-code-before-reset-password',
  FORGET_PASSWORD_RESET: 'public/reset-password-with-verify-code',
  FORGET_PIN_GET_VERIFICATION_CODE: 'public/reset-pin/verify-code',
  FORGET_PIN_RESET: 'public/reset-pin-with-verify-code',
  SUBSCRIBE_PUSH_NOTIFICATION: 'notification/subscribe',
  SUBSCRIBE_PUSH_NOTIFICATION_PUBLIC: 'public/notification/subscribe',
  UNSUBSCRIBE_PUSH_NOTIFICATION: 'notification/unsubscribe',
  UNSUBSCRIBE_PUSH_NOTIFICATION_PUBLIC: 'public/notification/unsubscribe',
  CHANGE_PASSWORD: 'change-password',
  CHANGE_PIN: 'change-pin',
  RELOAD_USER: 'reload-user',
  SETUP_PIN: 'setup-pin',
  VERIFY_PIN: 'verify-pin',
  REGISTER_THIRDPARTY: 'public/register-thirdparty',
  GET_OCCUPATIONS: 'public/get-occupations',
  GET_INDUSTRIES: 'public/get-industry',
  GET_SEND_MONEY_ACTIVITIES: 'public/get-sent-money',
  GET_3DAYS_SCHEDULE: 'user/get-3day-schedules',
  ADD_SCHEDULE: 'user/add-schedule',
  DELETE_SCHEDULE: 'user/delete-schedule',
  UPDATE_SCHEDULE: 'user/update-schedule',
  GET_SCHEDULES: 'user/get-schedules',
  VERIFY_DOCUMENT: 'compliance/verify-document',
  TOGGLE_NOTIFICATION: 'notification/toggle-notification',
  GET_ACCOUNT_BALANCE: 'account/balance',
  DAILY_CHECK_IN: 'user/sign-in',
  GET_DAILY_CHECK_IN_DAYS: 'user/sign-in-dates',
  GET_ACCOUNT_HISTORY: 'account/account-history',
  GET_MY_CURRENT_POINTS: 'account/get-my-point',
  GET_POINT_HISTORY: 'account/point-history',
  GET_MY_PROMOTIONS: 'account/promotions',
  INCREASE_LIMIT: 'increase-limit',
  GET_SENDER_LIST: 'account/sender-account-history',
  DELETE_SENDER: 'account/delete-sender',
  ADD_RECEIVER: 'account/add-beneficiary',
  EDIT_RECEIVER: 'account/edit-beneficiary',
  DELETE_RECEIVER: 'account/delete-beneficiary',
  VERIFY_RECEIVER: 'account/verify-beneficiary',
  GET_PDF: 'files/', // domain.com/files/ElectronicTransferSlip-test_8011006032_201907231036971.pdf
  FILL_PDF: 'fillform/submitrequest',
  SIGN_PDF: 'fillform/signit',
  CHECK_TRANSACTION: 'transaction/checkTxn',
  GET_TRANSACTION_AMOUNT_24H: 'transaction/get-trans-amount-in24h',
  SAVE_INCOMPLETED_TRANSACTION: 'transaction/add-trans-info',
  GET_INCOMPLETED_TRANSACTION: 'transaction/trans-info',
  GET_TRANSACTION_MESSAGES: 'transaction/message-list',
  GET_PUBLIC_MESSAGES: 'public/transaction/message-list',
  READ_ALL_MESSAGES: 'transaction/read-all-message',
  READ_MESSAGE: 'transaction/read-message/', // transaction/read-message/100299
  DELETE_MESSAGE: 'transaction/delete-message/', // transaction/delete-message/100299
  CHECK_TRANSACTION_FEE: 'transaction/checkfees',
  QUERY_INTERAC_RESULT: 'transaction/interacResult',
  GET_TRANSACTION_RATE: 'transaction/fxrate', // transaction/fxrate?payerId=216&instruments=CAD_IDR&amount=1000
  GET_PUBLIC_TRANSACTION_RATE: 'public/transaction/fxrate', // transaction/fxrate?payerId=216&instruments=CAD_IDR&amount=1000
  SUBMIT_TRANSACTION: 'transaction/submit',
  GET_TRANSACTION_VERIFICATION_CODE: 'transaction/verify-code',
  CHECK_TRANSACTION_ORDER: 'transaction/check-order',
  GET_ALL_TRANSACTIONS: 'transaction/alltransactions',
  GET_TRANSACTION_HISTORY_LIST: 'transaction/history-list',
  GET_TRANSACTION_HISTORY_CODE: 'transaction/view-etransfer-info?txnId=', // transaction/view-etransfer-info?txnId=210826T00990011
  MODIFY_PROFILE: 'transaction/modify-profile',
  UPDATE_TRANSACTION_INFO: 'transaction/edit-account',
  GET_SERVICE_FEE: 'transaction/get-service-fee',
  GET_UNREAD_MESSAGE_COUNT: 'transaction/unread-message-count',
  GET_TRANSACTION_DETAILS: 'transaction/details', // transaction/details?txnId=220124T00990013
  GET_PROVINCES: 'public/get-province/', // public/get-province/FRA
  GET_RECENT_2_TRANSACTIONS: 'transaction/recentTwoTxns',
  GET_RECENT_ALL_TRANSACTIONS: 'transaction/allTxns',
  // points - redeem
  GET_MY_POINTS: 'account/get-point',
  GET_AVAIL_REDEEMABLES: 'public/account/get-point',
  REDEEM_CARDS: 'point/redeem-discount-card',
  GET_REDEEM_CARD_HISTORY: 'point/redeem-discount-card-history/remit',
  GET_INACTIVATED_CARDS: 'point/get-my-card-inactive',
  GET_ACTIVATED_CARDS: 'point/get-my-card-active',
  GET_MY_CARD_DETAIL: 'point/get-my-card-detail/', // point/get-my-card-detail/{cardNumber}
  GET_CARD_BARCODE: 'point/get-barcode-image/', // point/get-barcode-image/{authCode}
  DELETE_CARD: 'point/delete-card',
  // referral
  APPLY_REFERRER: 'agent/apply',
  GET_BALANCE: 'agent/balance',
  WITHDRAW_BALANCE: 'agent/withdraw',
  REDEEM_COMMISSION_CARD: 'agent/redeem-commission-card',
  GET_INACTIVE_CARDS: 'agent/inactive-card',
  GET_ACTIVE_CARDS: 'agent/active-card',
  GET_CARD_DETAIL: 'agent/card-details/', // agent/card-details/{cardNumber}
  GET_ESSO_CARD_BARCODE: 'agent/barcode-image/', // agent/barcode-image/{authCode}
  GET_CARD_HISTORY: 'agent/card-history',
  ACTIVATE_CARD: 'agent/active-card',
  GET_REWARD_LIST: 'agent/reward-list',
  GET_PERKS_LIST: 'account/get-perks',
  GET_PUBLIC_PERKS_LIST: 'public/account/get-perks',
  SUBMIT_PERK: 'account/get-perks',
  SET_CURRENCY_PREFERENCE: 'setup-currency-preference',
  SET_REDEMPTION_PREFERENCE: 'setup-redemption-preference',
  // survey
  GET_SURVEY: 'user/survey', // user/survey?surveyId=1
  SUBMIT_SURVEY: 'user/submit-survey',
};
