import { HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, of, from } from 'rxjs';
import { catchError, map, retry, pluck, switchMap } from 'rxjs/operators';
import { API } from '../constants/api.constant';
import { AppInfo } from '../models/app.model';
import { ResponseInfo } from '../models/http.model';
import { Payer } from '../models/transaction.model';
import { CustomHttpClientService } from './custom-http-client.service';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEY } from '../constants/storage.constant';
import { ChineseNewYearPage } from 'src/app/shared/modals/chinese-new-year/chinese-new-year.page';
import { ModalController } from '@ionic/angular/standalone';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(private http: CustomHttpClientService) { }

  private modalController = inject(ModalController);

  appData: AppInfo;
  currentLang;

  private getInitLang(): Observable<string> {
    const notFirstTime = localStorage.getItem('notFirstTime');
    if (!!notFirstTime) {
      return of(null);
    }
    localStorage.setItem('notFirstTime', 'true');
    return (from(Device.getLanguageCode()) as Observable<{ value: string | null }>).pipe(pluck('value'));
  }

  public initApp(): Promise<any> {
    const forcedLoggedOut = localStorage.getItem('forcedLoggedOut');
    if (!forcedLoggedOut) {
      Preferences.clear();
      localStorage.setItem('forcedLoggedOut', 'true');
    }
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise((resolve) => {
      this.getInitLang().pipe(switchMap(lang => {
        if (!lang) {
          return this.getAppInfo();
        }
        const useLang = (lang.toLowerCase()).indexOf('zh') > -1 ? 'cn' : 'en';
        this.currentLang = useLang;
        return from(Preferences.set({ key: STORAGE_KEY.LANGUAGE, value: useLang })).pipe(switchMap(() => this.getAppInfo()));
      })).subscribe(
        appData => this.appData = appData,
        err => resolve(null),
        () => resolve(null)
      );
    });
  }

  public getAppInfo(): Observable<AppInfo> {
    return this.http.get(API.PREFIX + API.APP_INFO).pipe(
      map((res: ResponseInfo) => res.result),
      retry(3),
      catchError(() => {
        return EMPTY;
      })
    );
  }

  public getMessages(): Observable<any> {
    return this.http
      .get(API.PREFIX + API.APP_MESSAGES, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(
        map((res: ResponseInfo) => res.result),
        retry(3),
        catchError(() => {
          return EMPTY;
        })
      );
  }

  public getPayerList(payload: {
    countryIsoCode: string;
    serviceType?: string;
  }): Observable<Payer[]> {
    return this.http.post(API.PREFIX + API.PAYER_LIST, payload).pipe(
      retry(3),
      map((res: ResponseInfo) => res.result),
      catchError(() => {
        return EMPTY;
      })
    );
  }

  public traceAction(payload: {
    action: string;
    pageName?: string;
    desc?: string;
    machine_id?: string;
  }): Observable<any> {
    return this.http.post(API.PREFIX + API.TRACE_ACTION, payload, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    });
  }

  public contactUs(
    payload: {
      name?: string,
      phone?: string;
      email?: string;
      message?: string;
    } = {}
  ): Observable<any> {
    return this.http.post(API.PREFIX + API.CONTACT_US, payload);
  }

  public async showNewYearModal() {
    const modal = await this.modalController.create({
      component: ChineseNewYearPage,
      cssClass: 'new-year-modal',
      id: 'new-year-modal',
      backdropDismiss: true,
    });

    modal.present();
  }
}
