import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ForgetPasswordGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private navController: NavController
  ) {}
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if (this.authService.forgetPasswordInfo) {
      return true;
    }
    this.navController.navigateRoot('/forget-password', {
      animationDirection: 'forward',
    });
    return false;
  }
}
