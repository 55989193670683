import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
// import { AppsFlyer } from 'appsflyer-capacitor-plugin';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
// import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private platform: Platform, private appService: AppService) {
    this.initFirebase();
    // this.initAppsflyer();
  }

  async initFirebase() {
    if (!this.platform.is('capacitor')) {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }
  }

  // async initAppsflyer() {
  //   if (this.platform.is('capacitor')) {
  //     AppsFlyer.initSDK(environment.appsflyer);
  //     // AppTrackingTransparency.requestPermission().then(res => console.log('AppTrackingTransparency status: ' + res.status));
  //     AppsFlyer.getAppsFlyerUID().then(res => localStorage.setItem('AppsFlyerUID', res.uid));
  //   }
  // }

  // getAppsFlyerUID() {
  //   AppsFlyer.getAppsFlyerUID().then(res => localStorage.setItem('AppsFlyerUID', res.uid));
  // }

  setUser(userId: string) {
    if (userId) {
      FirebaseAnalytics.setUserId({ userId });
      // AppsFlyer.setCustomerUserId({ cuid: userId });
    }
  }

  logEvent(event: {
    eventName: string;
    params?: { buttonName?: string; pageName?: string; desc?: string };
  }) {
    this.appService.traceAction({ action: event.eventName }).subscribe();
    if (this.platform.is('capacitor') && environment.production) {
      FirebaseAnalytics.logEvent({
        name: event.eventName,
        params: event.params,
      });
      // AppsFlyer.logEvent({
      //   eventName: event.eventName,
      //   eventValue: event.params,
      // });
    }
  }

  // not supported in web
  setScreenName(page: { name: string; desc?: string }) {
    FirebaseAnalytics.setScreenName({
      screenName: page.name,
      nameOverride: page.name,
    });
  }
}
