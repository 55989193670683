import { Component, OnInit, inject } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { IonButton, IonIcon } from "@ionic/angular/standalone";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-gcash-promo',
  templateUrl: './gcash-promo.component.html',
  styleUrls: ['./gcash-promo.component.scss'],
  standalone: true,
  imports: [TranslateModule, IonIcon, IonButton],
  providers: [ModalController]
})
export class GcashPromoComponent implements OnInit {

  constructor() { }

  private modalController = inject(ModalController);
  private navController = inject(NavController);

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss();
  }

  sendMoney() {
    this.dismiss();
    this.navController.navigateForward('transaction/step-amount');
  }

}
