import { Component, NgZone, ViewChild } from '@angular/core';
import { Platform, AlertController, ToastController, ModalController } from '@ionic/angular';
import { AuthService } from './core/services/auth.service';
import { TransactionService } from './core/services/transaction.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppService } from './core/services/app.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './core/services/analytics.service';
import { BackButtonEvent } from '@ionic/core';
import { PushNotificationService } from './core/services/push-notification.service';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { PushNotifications } from '@capacitor/push-notifications';
import { PointsService } from './core/services/points.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { InitPromoPage } from './pages/init-promo/init-promo.page';
import { SpecialReferralOfferHintPage } from './shared/modals/special-referral-offer-hint/special-referral-offer-hint.page';
import { ChineseNewYearPage } from './shared/modals/chinese-new-year/chinese-new-year.page';
import { OttRemitPlayInstallReferrer } from 'capacitor-play-install-referrer-library';
import { GcashPromoComponent } from './shared/modals/gcash-promo/gcash-promo.component';
import { addIcons } from 'ionicons';
import { notificationsOutline, arrowDown, caretDownOutline, closeOutline, informationCircleOutline, readerOutline, helpCircleOutline, arrowForwardOutline, giftOutline, calendarOutline, lockOpenOutline, downloadOutline, shareSocialOutline, chevronForwardOutline, checkmarkCircleOutline, checkmarkOutline, lockClosedOutline, eyeOffOutline, eyeOutline, ellipsisHorizontalOutline } from 'ionicons/icons';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private authService: AuthService,
    private zone: NgZone,
    private transactionService: TransactionService,
    private appService: AppService,
    private router: Router,
    private alertController: AlertController,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private toastController: ToastController,
    private pushNotificationService: PushNotificationService,
    private pointsService: PointsService,
    private modalController: ModalController,
  ) {
    addIcons({
      notificationsOutline, arrowDown, caretDownOutline, closeOutline, informationCircleOutline,
      readerOutline, helpCircleOutline, arrowForwardOutline, giftOutline, calendarOutline, lockOpenOutline,
      downloadOutline, shareSocialOutline, chevronForwardOutline, checkmarkCircleOutline, checkmarkOutline,
      lockClosedOutline, eyeOffOutline, eyeOutline, ellipsisHorizontalOutline
    });
    this.initializeApp();
  }

  subscription = new Subscription();
  subscriptionCount = 0;
  @ViewChild('iosDownloadLink') iosDownloadLink;
  @ViewChild('androidDownloadLink') androidDownloadLink;
  showingForceUpdate = false;
  offlineToast: HTMLIonToastElement;
  machineId: string = null;

  initializeApp() {
    // Universal link listener setup
    // https://capacitorjs.com/docs/guides/deep-links
    // used for interac transaction
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split("crossborder-remittance.ott.ca").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });

    this.platform.ready().then(() => {
      SplashScreen.hide();
      this.showPromoPage(this.appService.appData && this.appService.appData.initPromo);
      if (this.platform.is('capacitor') && this.platform.is('android')) {
        OttRemitPlayInstallReferrer.getReferrerDetails().then(res => {
          this.appService.traceAction({
            action: 'track-referrer',
            desc: res.referrerUrl,
          }).subscribe();
        });
        StatusBar.setStyle({ style: Style.Dark });
        StatusBar.show();
      }

      Device.getId().then(value => {
        Preferences.set({ key: 'MACHINE_ID', value: value.identifier });
        this.machineId = value.identifier;
        this.pushNotificationService.initPush(value.identifier);
        this.appService.traceAction({
          action: 'log-machine-id',
          pageName: this.platform.platforms().join('-'),
          desc: `${this.platform.width()}x${this.platform.height()}`,
          machine_id: value.identifier
        }).subscribe();
      });

      this.forceUpdate();

      // trace page navigation event
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.toastController.dismiss().catch(() => { });
          if (this.subscriptionCount > 20) {
            this.subscription.unsubscribe();
            this.subscription = new Subscription();
            this.subscriptionCount = 0;
          }
          this.subscription.add(
            this.appService
              .traceAction({
                action: 'page view',
                pageName: event.urlAfterRedirects,
              })
              .subscribe()
          );
          this.analyticsService.setScreenName({ name: event.urlAfterRedirects });
          this.subscriptionCount++;
          if (event.urlAfterRedirects === '/tabs/home') {
            if (this.authService.isAuthenticated.getValue()) {
              this.subscriptionCount++;
              this.subscription.add(this.transactionService.getUnreadMessageCount().subscribe(count => {
                this.transactionService.unreadMessageCount$.next(count);
              }));
            } else {
              this.subscriptionCount++;
              this.subscription.add(this.transactionService.getPublicMessages(this.machineId).subscribe(messages => {
                Preferences.get({ key: 'readMessages' }).then(result => {
                  if (result.value) {
                    const obj = JSON.parse(result.value);
                    const arr = [];
                    messages.map(message => {
                      if (!obj[message.id]) {
                        arr.push(message.id);
                      }
                    });
                    this.transactionService.unreadMessageCount$.next(arr.length);
                  } else {
                    Preferences.set({ key: 'readMessages', value: JSON.stringify({}) });
                    this.transactionService.unreadMessageCount$.next(messages.length);
                  }
                });
              }));
            }
          } else if (event.urlAfterRedirects === '/tabs/my-points') {
            this.pointsService.refreshPoints$.next(true);
          }
        }
      });

      // refresh token if user's token is still valid
      this.authService.validateAuthToken().subscribe((isValid) => {
        if (isValid) {
          this.authService.refreshToken().subscribe(res => {
            if (res.pendingETransfer) {
              this.showPendingETransferPaymentAlert(res.pendingETransfer);
            }
          });
        }
      });

      // re-retrive app info
      this.appService
        .getAppInfo()
        .subscribe((res) => {
          this.appService.appData = res;
        });

      App.addListener('appStateChange', ({ isActive }) => {
        if (this.platform.is('capacitor')) {
          if (isActive) {
            this.forceUpdate();
            PushNotifications.checkPermissions().then(status => {
              if (status.receive === 'granted' && localStorage.getItem('no.push')) {
                PushNotifications.register();
              }
            });
          } else {
            PushNotifications.checkPermissions().then(status => {
              if (status.receive !== 'granted') {
                localStorage.setItem('no.push', 'true');
              } else {
                localStorage.removeItem('no.push');
              }
            });
          }
        }
      });

      // handle android back button
      document.addEventListener('ionBackButton', (ev: BackButtonEvent) => {
        ev.detail.register(-1, () => {
          const path = window.location.pathname;
          if (this.platform.is('android') && (path === '/tabs/home' || path === '/tabs/history' || path === '/tabs/my-points' || path === '/tabs/account')) {
            App.exitApp();
          }
        });
      });

      this.transactionService.triggerSpecialReferralOfferHint$.subscribe(referralCode => {
        if (!!referralCode) {
          this.showSpecialReferralOfferHint(referralCode);
          localStorage.removeItem('shownNewYearModal');
        }
      });
    });
  }

  private async showSpecialReferralOfferHint(referralCode: string) {
    let component;
    switch (referralCode.toUpperCase()) {
      case 'OTTEVENTS':
        component = SpecialReferralOfferHintPage;
        break;
      case 'GCASH':
        component = GcashPromoComponent;
        break;
      default:
        break;
    }
    if (!component) {
      return;
    }
    const modal = await this.modalController.create({
      component,
      cssClass: 'auto-height special-referral-offer-hint',
      backdropDismiss: true,
    });

    modal.present();
  }

  private async showPromoPage(config) {
    if (!environment.production) {
      return;
    }
    let options = null;
    try {
      options = JSON.parse(config);
      if (options) {
        const modal = await this.modalController.create({
          component: InitPromoPage,
          componentProps: options,
          cssClass: 'init-promo',
          id: 'promotion-modal',
          animated: false,
        });

        return await modal.present();
      }
    } catch (e) { }
  }


  private forceUpdate() {
    if (!this.platform.is('capacitor')) {
      return;
    }
    if (this.appService.appData) {
      let current;
      let required;
      if (this.platform.is('ios')) {
        current = environment.version.ios;
        required = this.appService.appData.iOSVersion;
      } else {
        current = environment.version.android;
        required = this.appService.appData.androidVersion;
      }
      if (current < required && !this.showingForceUpdate) {
        this.showDownloadAppAlert();
      }
    }
  }

  private async showDownloadAppAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('common.hasUpdate'),
      backdropDismiss: false,
      message: this.translateService.instant('message.force.update'),
      buttons: [
        {
          text: this.translateService.instant('common.update'),
          handler: () => {
            if (this.platform.is('ios')) {
              this.iosDownloadLink.nativeElement.click();
            } else {
              this.androidDownloadLink.nativeElement.click();
            }
            return false;
          },
        },
      ],
    });

    alert.onDidDismiss().then(() => this.showingForceUpdate = false);

    await alert.present().then(() => this.showingForceUpdate = true);
  }

  private async showPendingETransferPaymentAlert(transactionId: string) {
    if (!transactionId) {
      return;
    }
    const alert = await this.alertController.create({
      header: this.translateService.instant('message.pending.payment'),
      message: this.translateService.instant('message.pending.etransfer'),
      buttons: [
        {
          text: this.translateService.instant('tabs.history.paymentDetails'),
          handler: () => {
            this.router.navigate(['/tabs/history/view-etransfer-code'], { queryParams: { txn: transactionId } });
          }
        }
      ]
    });

    return await alert.present();
  }
}
