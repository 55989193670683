import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ForgetPasswordGuard } from './core/guards/forget-password.guard';
import { PublicGuard } from './core/guards/public.guard';
import { TransactionGuard } from './core/guards/transaction.guard';

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/auth/login/login.page').then((c) => c.LoginPage),
    canLoad: [PublicGuard],
  },
  {
    path: 'signup',
    loadComponent: () => import('./pages/auth/signup/signup.page').then((c) => c.SignupPage),
    canLoad: [PublicGuard],
  },
  {
    path: 'transaction',
    loadChildren: () =>
      import('./pages/transaction/transaction.module').then(
        (m) => m.TransactionPageModule
      ),
  },
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full',
  },
  {
    path: 'signup-verification-code',
    loadComponent: () => import('./pages/auth/signup-verification-code/signup-verification-code.page').then((c) => c.SignupVerificationCodePage),
    canLoad: [PublicGuard],
  },
  {
    path: 'forget-password-verification-code',
    loadComponent: () => import('./pages/auth/forget-password-verification-code/forget-password-verification-code.page').then((c) => c.ForgetPasswordVerificationCodePage),
    canLoad: [PublicGuard, ForgetPasswordGuard],
  },
  {
    path: 'forget-password-reset',
    loadComponent: () => import('./pages/auth/forget-password-reset/forget-password-reset.page').then((c) => c.ForgetPasswordResetPage),
    canLoad: [PublicGuard, ForgetPasswordGuard],
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs-routing.module').then((m) => m.routes),
  },
  {
    path: 'my-messages',
    loadComponent: () => import('./pages/my-messages/my-messages.page').then((c) => c.MyMessagesPage),
  },
  {
    path: 'capture-id-photo',
    loadComponent: () => import('./pages/capture-id-photo/capture-id-photo.page').then(c => c.CaptureIdPhotoPage)
  },
  {
    path: 'view-agreement',
    loadChildren: () => import('./shared/modals/view-agreement/view-agreement.module').then(m => m.ViewAgreementPageModule)
  },
  {
    path: 'download-app',
    loadComponent: () => import('./pages/download-app/download-app.page').then(c => c.DownloadAppPage)
  },
  {
    path: 'signup-more',
    loadComponent: () => import('./pages/auth/signup-more/signup-more.page').then(c => c.SignupMorePage)
  },
  {
    path: 'first-guide',
    loadComponent: () => import('./pages/first-guide/first-guide.page').then(c => c.FirstGuidePage)
  },
  {
    path: 'doc-country-select',
    loadChildren: () => import('./shared/modals/doc-country-select/doc-country-select.module').then(m => m.DocCountrySelectPageModule)
  },
  {
    path: 'confirm-email-or-phone',
    loadChildren: () => import('./shared/modals/confirm-email-or-phone/confirm-email-or-phone.module').then(m => m.ConfirmEmailOrPhonePageModule)
  },
  {
    path: 'verify-dob',
    loadChildren: () => import('./shared/modals/verify-dob/verify-dob.module').then(m => m.VerifyDobPageModule)
  },
  {
    path: 'my-recipients',
    loadComponent: () => import('./pages/account/pages/my-recipients/my-recipients.page').then((m) => m.MyRecipientsPage),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'my-recipient-info',
    loadComponent: () => import('./pages/account/pages/my-recipient-info/my-recipient-info.page').then((c) => c.MyRecipientInfoPage),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction-details',
    loadChildren: () => import('./pages/history/transaction-details/transaction-details.module').then(m => m.TransactionDetailsPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'transaction-details/:txnId',
    loadChildren: () => import('./pages/history/transaction-details/transaction-details.module').then(m => m.TransactionDetailsPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-pin',
    loadComponent: () => import('./pages/account/pages/forgot-pin/forgot-pin.page').then((c) => c.ForgotPinPage),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'referral',
    loadChildren: () => import('./pages/referral/referral.module').then(m => m.ReferralPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'send-again-review',
    loadChildren: () => import('./shared/modals/send-again-review/send-again-review.module').then(m => m.SendAgainReviewPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, TransactionGuard],
  },
  {
    path: 'send-again-done',
    loadChildren: () => import('./shared/modals/send-again-done/send-again-done.module').then(m => m.SendAgainDonePageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard, TransactionGuard],
  },
  {
    path: 'recent-transaction-detail',
    loadChildren: () => import('./pages/recent-transaction-detail/recent-transaction-detail.module').then(m => m.RecentTransactionDetailPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'choose-envelope',
    loadComponent: () => import('./pages/choose-envelop/choose-envelop.page').then(c => c.ChooseEnvelopPage),
    canLoad: [AuthGuard],
  },
  {
    path: 'choose-greeting-card',
    loadComponent: () => import('./pages/choose-greeting-card/choose-greeting-card.page').then(c => c.ChooseGreetingCardPage),
    canLoad: [AuthGuard],
  },
  {
    path: 'customize-envelop',
    loadComponent: () => import('./pages/customize-envelop/customize-envelop.page').then(c => c.CustomizeEnvelopPage)
  },
  {
    path: 'cashpickup-hint',
    loadChildren: () => import('./shared/modals/cashpickup-hint/cashpickup-hint.module').then(m => m.CashpickupHintPageModule)
  },
  {
    path: 'ewallet-hint',
    loadChildren: () => import('./shared/modals/ewallet-hint/ewallet-hint.module').then(m => m.EwalletHintPageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'refer-friends',
    loadComponent: () => import('./pages/account/pages/refer-friends/refer-friends.page').then((c) => c.ReferFriendsPage),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'signup-more-done',
    loadComponent: () => import('./pages/auth/signup-more-done/signup-more-done.page').then(c => c.SignupMoreDonePage)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
