import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomHttpClientService } from './custom-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SlackMessagingService {

  constructor(private http: CustomHttpClientService) { }

  sendErrorMsg(text: string, attachments?: Array<{author_name: string, color: 'danger', title: string, text: string}>) {
    const options = {
      headers: new HttpHeaders(
        { 'Content-Type': 'application/x-www-form-urlencoded' }
      )
    };
    this.http.post(environment.slackMsg, { text, attachments }, options).pipe(take(1)).subscribe();
  }
}
