import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, pluck, switchMap, tap } from 'rxjs/operators';
import { API } from '../constants/api.constant';
import {
  ForgetPasswordPayload,
  ForgetPinPayload,
  LoginPayload,
  SignupPayload,
  VERIFICATION_TYPE,
} from '../models/auth.model';
import { ResponseInfo } from '../models/http.model';
import { CustomHttpClientService } from './custom-http-client.service';
import { JwtService } from './jwt.service';
import { UserService } from './user.service';

import { Preferences } from '@capacitor/preferences';
import { DEVICE_ID_KEY, PushNotificationService } from './push-notification.service';
import { LoadingController } from '@ionic/angular';
import { STORAGE_KEY } from '../constants/storage.constant';
import { AnalyticsService } from './analytics.service';
import { HttpHeaders } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: CustomHttpClientService,
    private jwtService: JwtService,
    private userService: UserService,
    private pushNotificationService: PushNotificationService,
    private loadingController: LoadingController,
    private analyticsService: AnalyticsService
  ) { }

  signupInfo: any = null;
  forgetPasswordInfo: ForgetPasswordPayload = null;
  isAuthenticated = new BehaviorSubject<boolean>(null);

  validateAuthToken(): Observable<boolean> {
    return from(Preferences.get({ key: STORAGE_KEY.TOKEN })).pipe(
      switchMap(({ value }) => {
        if (value && !this.jwtService.isTokenExpired(value)) {
          this.isAuthenticated.next(true);
          return of(true);
        }

        Preferences.remove({ key: STORAGE_KEY.TOKEN });
        this.isAuthenticated.next(false);
        return of(false);
      })
    );
  }

  login(credential: LoginPayload): Observable<any> {
    return this.http.post(API.PREFIX + API.LOGIN, credential).pipe(
      switchMap((res: ResponseInfo) => {
        const token = res.result.token;
        const cid = res.result.profile?.user?.cid;
        this.analyticsService.setUser(cid);
        return from(Preferences.set({ key: STORAGE_KEY.TOKEN, value: token })).pipe(tap(() => this.isAuthenticated.next(true)));
      }),
      switchMap(() => from(Preferences.get({ key: DEVICE_ID_KEY })).pipe(switchMap(result => this.pushNotificationService.subscribePush(result.value)))),
      switchMap(() => {
        if (Capacitor.getPlatform() === 'ios') {
          SavePassword.promptDialog({
            username: credential.userkey,
            password: credential.password
          });
        }
        return this.userService.reloadUser()
      })
    );
  }

  refreshToken(): Observable<any> {
    return this.http.post(API.PREFIX + API.REFRESH_TOKEN, null).pipe(
      switchMap((res: ResponseInfo) => {
        const token = res.result.token;
        return from(Preferences.set({ key: STORAGE_KEY.TOKEN, value: token }));
      }),
      switchMap((_) => this.userService.reloadUser())
    );
  }

  /**
   * email/phone 验证二选一
   */
  verifyUserAccount(payload: {
    sendType: VERIFICATION_TYPE;
    destination: string;
  }): Observable<any> {
    return this.http
      .post(API.PREFIX + API.FORGET_PASSWORD_VERIFYING, payload)
      .pipe(map((res: any) => res.result));
  }

  /**
   * email/phone 验证二选一
   */
  getVerificationCode(payload: {
    type: VERIFICATION_TYPE;
    email?: string;
    phone?: string;
  }): Observable<any> {
    return this.http.post(
      API.PREFIX + API.SIGNUP_GET_VERIFICATION_CODE,
      payload
    );
  }

  signup(payload: SignupPayload): Observable<any> {
    return this.http.post(API.PREFIX + API.SIGNUP, payload).pipe(
      tap((res: ResponseInfo) => {
        const cid = res.result.customerId;
        this.analyticsService.setUser(cid);
        this.registerThirdParty({
          customerId: cid,
          thirdpartyId: localStorage.getItem('AppsFlyerUID'),
        }).subscribe();
      }),
      switchMap((_) => {
        const login: LoginPayload = {
          userkey: payload.phone,
          password: payload.password,
          platform: 'MOB',
          usertype: 'CUSTOMER',
        };

        return this.login(login);
      })
    );
  }

  logout(): Observable<any> {
    return this.pushNotificationService.unsubscribePush().pipe(
      switchMap(() =>
        this.http.post(API.PREFIX + API.LOGOUT, null).pipe(
          switchMap((_) => {
            this.isAuthenticated.next(false);
            this.userService.userProfile$.next(null);
            return from(Preferences.remove({ key: STORAGE_KEY.TOKEN }));
          })
        )
      ),
      catchError(async (error) => {
        Preferences.clear();
        const top = await this.loadingController.getTop();
        if (top) {
          this.loadingController.dismiss();
        }
        return EMPTY;
      })
    );
  }

  sendForgetPasswordVerificationCode(payload: {
    destination: string;
    sendType: VERIFICATION_TYPE;
    dob?: string;
  }): Observable<any> {
    return this.http.post(
      API.PREFIX + API.FORGET_PASSWORD_GET_VERIFICATION_CODE,
      payload
    );
  }

  forgetPasswordVerifyCodeFirst(payload: ForgetPasswordPayload): Observable<any> {
    return this.http.post(API.PREFIX + API.FORGET_PASSWORD_VERIFY_CODE_FIRST, payload);
  }

  forgetPasswordReset(payload: ForgetPasswordPayload): Observable<any> {
    return this.http.put(API.PREFIX + API.FORGET_PASSWORD_RESET, payload);
  }

  changePassword(payload: {
    newPassword: string;
    currentPassword: string;
  }): Observable<any> {
    return this.http.put(API.PREFIX + API.CHANGE_PASSWORD, payload);
  }

  changePin(payload: { newPin: string; currentPin: string }): Observable<any> {
    return this.http.put(API.PREFIX + API.CHANGE_PIN, payload);
  }

  verifyPin(payload: { pin: string }): Observable<any> {
    return this.http
      .post(API.PREFIX + API.VERIFY_PIN, payload)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  sendForgetPinVerificationCode(payload: {
    destination: string;
    sendType: string;
  }): Observable<any> {
    return this.http.post(
      API.PREFIX + API.FORGET_PIN_GET_VERIFICATION_CODE,
      payload
    );
  }

  forgetPinReset(payload: ForgetPinPayload): Observable<any> {
    return this.http.put(API.PREFIX + API.FORGET_PIN_RESET, payload);
  }

  increaseLimit(): Observable<any> {
    return this.http
      .post(API.PREFIX + API.INCREASE_LIMIT, null)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  verifyReferralCode(referCode: string): Observable<any> {
    return this.http
      .post(API.PREFIX + API.VERIFY_REFERRAL_CODE, { referCode })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  registerThirdParty(payload: {
    customerId?: string;
    thirdpartyId?: string;
  }): Observable<any> {
    return this.http.post(API.PREFIX + API.REGISTER_THIRDPARTY, payload, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    });
  }

  isEmailExisted(email: string): Observable<boolean> {
    return this.http.post(API.PREFIX + API.CHECK_EMAIL, { email }, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    }).pipe(map((res: ResponseInfo) => res.result));
  }
}
