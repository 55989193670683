export const environment = {
  production: true,
  API_URL: 'https://app.ottremit.com',
  slackMsg: 'https://hooks.slack.com/services/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',
  sentryUrl: 'https://b6b62c18385d4b2e82f1c9a5212c3c2a@o1265632.ingest.sentry.io/6449727',
  version: {
    ios: '1.7.9',
    android: '1.7.9',
  },
  download: {
    ios: 'https://apps.apple.com/ca/app/id1551385724',
    android: 'https://play.google.com/store/apps/details?id=com.ott.crossborder'
  },
  firebaseConfig: {
    apiKey: "AIzaSyDuONHOaCVbrr8lipHb01d5hWhjJ7LQPGI",
    authDomain: "ott-crossborder-remittance.firebaseapp.com",
    projectId: "ott-crossborder-remittance",
    storageBucket: "ott-crossborder-remittance.appspot.com",
    messagingSenderId: "12466819276",
    appId: "1:12466819276:web:53488c66e5e0546793b269",
    measurementId: "G-DZBJ77L9NK"
  },
  appsflyer: {
    appID: '1551385724',
    devKey: 'xnUukdPoV5v7qhtuDiDrDD',
    isDebug: false,
    waitForATTUserAuthorization: 10,
    registerOnDeepLink: true,
    registerConversionListener: true,
    timeToWaitForATTUserAuthorization: 120
  }
};
