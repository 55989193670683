import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: environment.sentryUrl,
    environment: environment.production ? 'production' : 'test',
    release: `ottremit-production-${environment.version.ios}`,
    dist: '1',
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', 'https://crossborder-remittance-test.ott.ca', 'https://crossborder-remittance.ott.ca', 'https://app.ottremit.com', 'https://app-test.ottremit.com'],
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
      // new Sentry.BrowserTracing({
      //   tracingOrigins: ['localhost', 'https://crossborder-remittance-test.ott.ca', 'https://crossborder-remittance.ott.ca', 'https://app.ottremit.com', 'https://app-test.ottremit.com'],
      //   routingInstrumentation: Sentry.routingInstrumentation,
      // }),
      // new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
