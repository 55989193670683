import { Directive, Input, HostListener } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';

@Directive({
  selector: '[trackClick]',
  standalone: true,
})
export class TrackClickDirective {
  constructor(private analyticsService: AnalyticsService) { }

  @Input() trackClick: { event?: string; button?: string; page?: string };

  @HostListener('click', ['$event']) onClick($event) {
    this.analyticsService.logEvent({
      eventName: this.trackClick.event || 'click',
      params: {
        buttonName: this.trackClick.button,
        pageName: this.trackClick.page,
      },
    });
  }
}
