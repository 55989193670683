import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, pluck, switchMap, tap } from 'rxjs/operators';
import { API } from '../constants/api.constant';
import { ResponseInfo } from '../models/http.model';
import { ScheduleEvent, UserProfile } from '../models/user.modal';
import { CustomHttpClientService } from './custom-http-client.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: CustomHttpClientService) { }

  occupations;
  industries;
  beenToPersonalInfo = false;

  public userProfile$ = new BehaviorSubject<UserProfile>(null);

  public reloadUser(): Observable<UserProfile> {
    return this.http.get(API.PREFIX + API.RELOAD_USER).pipe(
      map((res: ResponseInfo) => {
        this.userProfile$.next(res.result);
        Sentry.configureScope(scope => {
          scope.setUser({
            id: res.result.user.cid
          });
        });
        return res.result;
      })
    );
  }

  public setupPin(payload: { pin: string }): Observable<any> {
    return this.http.post(API.PREFIX + API.SETUP_PIN, payload);
  }

  public toggleNotification(): Observable<any> {
    return this.http
      .post(API.PREFIX + API.TOGGLE_NOTIFICATION, {})
      .pipe(switchMap((_) => this.reloadUser()));
  }

  public getOccupations(): Observable<any> {
    if (this.occupations) {
      return of(this.occupations);
    }
    return this.http.get(API.PREFIX + API.GET_OCCUPATIONS).pipe(tap(res => this.occupations = res));
  }

  public getIndustries(): Observable<any> {
    if (this.industries) {
      return of(this.industries);
    }
    return this.http.get(API.PREFIX + API.GET_INDUSTRIES).pipe(tap(res => this.industries = res));
  }

  public getSchedules(): Observable<Array<ScheduleEvent>> {
    return this.http.get(API.PREFIX + API.GET_SCHEDULES).pipe(map((res: any) => res.result));
  }

  public addSchedule(schedule: ScheduleEvent): Observable<any> {
    return this.http.post(API.PREFIX + API.ADD_SCHEDULE, schedule);
  }

  public updateSchedule(schedule: ScheduleEvent): Observable<any> {
    return this.http.post(API.PREFIX + API.UPDATE_SCHEDULE, schedule);
  }

  public deleteSchedule(schedule: ScheduleEvent): Observable<any> {
    return this.http.post(API.PREFIX + API.DELETE_SCHEDULE, schedule);
  }

  public get3DaysSchedules(): Observable<any> {
    return this.http.get(API.PREFIX + API.GET_3DAYS_SCHEDULE).pipe(map((res: any) => res.result));
  }

  public setCurrencyPreference(currencyPreference: string) {
    return this.http.post(API.PREFIX + API.SET_CURRENCY_PREFERENCE, { currencyPreference });
  }

  public setRedemptionPreference(redemptionPreference: string) {
    return this.http.post(API.PREFIX + API.SET_REDEMPTION_PREFERENCE, { redemptionPreference });
  }
}
