import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, EMPTY, from, Observable, of } from 'rxjs';
import { finalize, map, pluck, retry, switchMap, tap } from 'rxjs/operators';
import { API } from '../constants/api.constant';
import { ResponseInfo } from '../models/http.model';
import {
  CheckFeeResponse,
  FxRate,
  IdDocumentPayload,
  Payer,
  Point,
  RecentTransaction,
  Recipient,
  ServiceType,
  TransactionDetail,
  TransactionHistory,
  TransactionInfo,
  TransactionMessage,
} from '../models/transaction.model';
import { CustomHttpClientService } from './custom-http-client.service';
import { UserService } from './user.service';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CountryCurrency, CurrencyItem } from '../models/app.model';


@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  constructor(
    private http: CustomHttpClientService,
    private userService: UserService,
    private alertController: AlertController,
    private navController: NavController,
    private translate: TranslateService,
    private loadingController: LoadingController,
  ) { }

  public steps$ = this.getSteps();

  public selectedRedEnvelope: string;
  public savedBased64: string;

  public readonly currencyIcons = {
    CAD: 'assets/icons/countries/Canada.svg',
    INR: 'assets/icons/countries/India.svg',
    PHP: 'assets/icons/countries/Philippines.svg',
    LKR: 'assets/icons/countries/Sri Lanka.svg',
    CNY: 'assets/icons/countries/China.svg',
    NPR: 'assets/icons/countries/Nepal.svg',
    KRW: 'assets/icons/countries/South Korea.svg',
    SGD: 'assets/icons/countries/Singapore.svg',
    MXN: 'assets/icons/countries/Mexico.svg',
    MYR: 'assets/icons/countries/Malaysia.svg',
    JPY: 'assets/icons/countries/Japan.svg',
    ILS: 'assets/icons/countries/Israel.svg',
    EUR: 'assets/icons/countries/France.svg',
    GBP: 'assets/icons/countries/United Kingdom.svg',
    AUD: 'assets/icons/countries/Australia.svg',
    HKD: 'assets/icons/countries/Hong Kong.svg',
  };

  public pin: string;
  public transaction: TransactionInfo;
  public serviceFee: Array<{
    serviceName: ServiceType;
    fee: number;
  }>;
  public selectedRecentTransaction: RecentTransaction;
  public selectedRecipient: Recipient;
  public selectedCountryIsoCode: string;
  public selectedPayer: Payer;
  public enabledOptions = {
    CashPickup: false,
    MobileWallet: false,
    BankAccount: false,
  };
  public submitResponse: any;
  public senderIDRequried = false;
  public signedPdf;
  public tempPdf;
  public fileNames: string;
  public tempPdfUrl;
  public signature;
  public unreadMessageCount$ = new BehaviorSubject(0);
  public showHistoryVerifyPin$ = new BehaviorSubject(false);
  public historyRecord$: Observable<TransactionHistory[]> = EMPTY;
  public transactionFee = null;
  private newRecipient: Recipient;
  public selectedCountry = null;
  public usePointsToWaiveFee = false;
  public creatingNewRecipient = false;
  public recipientsChanged = false;
  public refreshRecentRecipients$ = new BehaviorSubject<boolean>(false);
  public triggerSpecialReferralOfferHint$ = new BehaviorSubject<string>('');
  public recent2Recipients$ = new BehaviorSubject<Array<any>>([]);

  public getSteps(): Observable<Array<any>> {
    return of([
      'tabs.transaction.step.amount',
      'tabs.transaction.step.time',
      'tabs.transaction.step.recipient',
      'tabs.transaction.step.payment',
    ]);
  }

  public async exitTransaction() {
    const alert = await this.alertController.create({
      header: this.translate.instant('common.confirm'),
      message: this.translate.instant('tabs.transaction.steps.discardTransaction'),
      buttons: [
        {
          text: this.translate.instant('common.cancel')
        },
        {
          text: this.translate.instant('common.discard'),
          role: 'destructive',
          handler: () => {
            this.navController.navigateRoot('/tabs/home', { animationDirection: 'forward' });
          }
        }
      ]
    });

    alert.present();
  }

  public initTransaction(): TransactionInfo {
    // init/reset transaction
    this.transactionFee = null;
    this.senderIDRequried = false;
    this.selectedPayer = null;
    this.submitResponse = null;
    this.signedPdf = null;
    this.tempPdf = null;
    this.signature = null;
    this.usePointsToWaiveFee = false;
    const userProfile = this.userService.userProfile$.getValue();
    if (userProfile) {
      this.transaction = new TransactionInfo({
        firstname: userProfile.user.cfirstname,
        lastname: userProfile.user.clastname,
      });
    } else {
      this.transaction = new TransactionInfo();
    }

    return this.transaction;
  }

  public saveTransation() {
    this.saveIncompletedTransaction({
      content: JSON.stringify(this.transaction),
    }).subscribe();
  }

  public discardTransation() {
    this.saveIncompletedTransaction({ content: null }).subscribe();
  }

  public getSendMoneyActivities(): Observable<any> {
    return this.http.get(API.PREFIX + API.GET_SEND_MONEY_ACTIVITIES, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    }).pipe(map((res: any) => res.result));
  }

  public checkTxn(): Observable<any> {
    return this.http.get(API.PREFIX + API.CHECK_TRANSACTION);
  }

  public getProvinces(countryCode: string): Observable<any> {
    return this.http.get(API.PREFIX + API.GET_PROVINCES + countryCode);
  }

  public getFxRate(payload: {
    amount: number;
    currency: string;
    payerId: string;
  }): Observable<FxRate[]> {
    const params = `?payerId=${payload.payerId}&instruments=CAD_${payload.currency}&amount=${payload.amount}`;
    return this.http
      .get(API.PREFIX + API.GET_TRANSACTION_RATE + params, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getPublicFxRate(payload: {
    amount: number;
    currency: string;
    payerId: string;
  }): Observable<FxRate[]> {
    const params = `?payerId=${payload.payerId}&instruments=CAD_${payload.currency}&amount=${payload.amount}`;
    return this.http
      .get(API.PREFIX + API.GET_PUBLIC_TRANSACTION_RATE + params, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getTransactionAmountIn24H(noLoading?: boolean): Observable<number> {
    let header = { headers: new HttpHeaders() };
    if (noLoading) {
      header = {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      }
    }
    return this.http
      .get(API.PREFIX + API.GET_TRANSACTION_AMOUNT_24H, header)
      .pipe(map((res: any) => res.result), pluck('amount'));
  }

  public getTransactionHistory(): Observable<TransactionHistory[]> {
    return this.http
      .get(API.PREFIX + API.GET_TRANSACTION_HISTORY_LIST, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getEtransferHistoryCode(orderId: string) {
    return this.http
      .get(API.PREFIX + API.GET_TRANSACTION_HISTORY_CODE + orderId)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public modifyProfile(payload: any) {
    this.showSubmitting();
    return this.http
      .post(API.PREFIX + API.MODIFY_PROFILE, payload)
      .pipe(map((res: ResponseInfo) => res.result), finalize(() => this.dismissSubmitting()));
  }

  public updateTransactionInfo(payload) {
    return this.http
      .post(API.PREFIX + API.UPDATE_TRANSACTION_INFO, payload)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getAccountHistory(): Observable<Recipient[]> {
    return this.http
      .get(API.PREFIX + API.GET_ACCOUNT_HISTORY)
      .pipe(map((res: ResponseInfo) => res.result), tap(() => this.recipientsChanged = false));
  }

  public getSenderAccountHistory(): Observable<Array<any>> {
    return this.http
      .get(API.PREFIX + API.GET_SENDER_LIST)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public deleteSender(sender: Recipient): Observable<any> {
    return this.http
      .post(API.PREFIX + API.DELETE_SENDER, { id: sender.id })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getPointHistory(): Observable<Point[]> {
    return this.http
      .get(API.PREFIX + API.GET_POINT_HISTORY)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getMyPromotions(): Observable<{
    availablePromotions: Array<any>;
    pastPromotions: Array<any>;
  }> {
    return this.http
      .get(API.PREFIX + API.GET_MY_PROMOTIONS)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  private saveIncompletedTransaction(payload: {
    content: string;
  }): Observable<any> {
    return this.http.post(
      API.PREFIX + API.SAVE_INCOMPLETED_TRANSACTION,
      payload
    );
  }

  public getIncompletedTransaction(): Observable<any> {
    return this.http
      .get(API.PREFIX + API.GET_INCOMPLETED_TRANSACTION, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public getTransactionMessages(): Observable<Array<TransactionMessage>> {
    return this.http
      .get(API.PREFIX + API.GET_TRANSACTION_MESSAGES)
      .pipe(map((res: any) => res.result));
  }

  public getPublicMessages(machineId?: string): Observable<Array<TransactionMessage>> {
    return from(Preferences.get({ key: 'MACHINE_ID' })).pipe(switchMap(result => {
      if (result.value) {
        return this.http
          .post(API.PREFIX + API.GET_PUBLIC_MESSAGES, { machine_id: machineId || result.value }, {
            headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
          })
          .pipe(map((res: any) => res.result))
      } else {
        return of([]);
      }
    }), map(((res: (Array<TransactionMessage>)) => res)));
  }

  public readMessages(message: TransactionMessage): Observable<any> {
    return this.http.get(API.PREFIX + API.READ_MESSAGE + message.id, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    });
  }

  public readAllMessages(): Observable<any> {
    return this.http.get(API.PREFIX + API.READ_ALL_MESSAGES);
  }

  public deleteMessage(message: TransactionMessage): Observable<any> {
    return this.http.get(API.PREFIX + API.DELETE_MESSAGE + message.id);
  }

  public getServiceFee(): Observable<
    Array<{
      serviceName: ServiceType;
      fee: number;
    }>
  > {
    return this.http.get(API.PREFIX + API.GET_SERVICE_FEE).pipe(
      map((res: ResponseInfo) => {
        this.serviceFee = res.result;
        return res.result;
      })
    );
  }

  public getServiceFeeWithCurrency(currency: string): Observable<
    Array<{
      serviceName: ServiceType;
      fee: number;
    }>
  > {
    return this.http.get(API.PREFIX + API.GET_SERVICE_FEE + '/' + currency).pipe(
      map((res: ResponseInfo) => {
        this.serviceFee = res.result;
        return res.result;
      })
    );
  }

  public downloadPdf(fileName: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<Blob>('/' + API.GET_PDF + fileName, {
      headers,
      responseType: 'blob' as 'json',
    });
  }

  public fillEFTPdf() {
    let amount = 0;
    if (this.transaction.inBound.isRedeemPoint) {
      amount = +this.transaction.inBound.amount;
    } else {
      amount =
        +this.transaction.inBound.amount + +this.transactionFee.fee.inFee;
    }
    const user = this.userService.userProfile$.getValue();
    const personAddress =
      this.transaction.inBound.personAddress +
      ', ' +
      this.transaction.inBound.personCity +
      ' ' +
      this.transaction.inBound.personProvince +
      ', ' +
      this.transaction.inBound.personPostalCode;
    const bankAccountNumber =
      (this.transaction.inBound.designationNumber || '') +
      this.transaction.inBound.bankAccountNumber;

    const payload = {
      formName: 'ElectronicTransferSlip',
      operation: 'fill',
      templateFilePath: 'OB004',
      fields: [
        {
          name: 'BranchNumber',
          value: this.transaction.inBound.bankBranchNumber,
          type: 'text',
        },
        {
          name: 'Institution',
          value: this.transaction.inBound.bankInstitutionNumber,
          type: 'text',
        },
        {
          name: 'AccountNo',
          value: bankAccountNumber,
          type: 'text',
        },
        {
          name: 'BankName',
          value: this.transaction.inBound.bankName,
          type: 'text',
        },
        { name: 'Branch', value: '', type: 'text' },
        {
          name: 'BranchAddress',
          value: this.transaction.inBound.bankAddress,
          type: 'text',
        },
        {
          name: 'CityProvince',
          value:
            this.transaction.inBound.bankCity &&
              this.transaction.inBound.bankProvince
              ? `${this.transaction.inBound.bankCity} ${this.transaction.inBound.bankProvince}`
              : '',
          type: 'text',
        },
        {
          name: 'Post Code',
          value: this.transaction.inBound.bankPostalCode,
          type: 'text',
        },
        {
          name: 'Currency',
          value: this.transaction.inBound.currency,
          type: 'text',
        },
        { name: 'Amount', value: amount, type: 'text' },
        { name: 'PayerName', value: user.user.cfullname, type: 'text' },
        { name: 'Address', value: personAddress, type: 'text' },
        { name: 'Telephone', value: user.user.mobile, type: 'text' },
      ],
    };
    return this.http.post(API.PREFIX + API.FILL_PDF, payload);
  }

  public signEFTPdf() {
    const payload = {
      formName: 'ElectronicTransferSlip',
      templateFilePath: this.fileNames,
      base64Image: this.signature,
      operation: 'sign',
    };
    return this.http.post(API.PREFIX + API.SIGN_PDF, payload);
  }

  public checkFees(): Observable<CheckFeeResponse> {
    const formData = new FormData();
    const tran = Object.assign({}, this.transaction);
    delete tran.doc;
    delete tran.quotationId;
    formData.append('data', JSON.stringify(tran));
    return this.http
      .post(API.PREFIX + API.CHECK_TRANSACTION_FEE, formData)
      .pipe(map((res: any) => res.result));
  }

  public saveNewRecipient(): Observable<any> {
    return this.http.post(API.PREFIX + API.ADD_RECEIVER, this.newRecipient);
  }

  public editRecipient(recipient: Recipient): Observable<any> {
    return this.http
      .post(API.PREFIX + API.EDIT_RECEIVER, recipient)
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public deleteRecipient(recipient: Recipient): Observable<any> {
    return this.http
      .post(API.PREFIX + API.DELETE_RECEIVER, { id: recipient.id })
      .pipe(map((res: ResponseInfo) => res.result), tap(() => this.recipientsChanged = true));
  }

  public getUnreadMessageCount(): Observable<any> {
    return this.http
      .get(API.PREFIX + API.GET_UNREAD_MESSAGE_COUNT, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map((res: any) => res.result));
  }

  public getTransactionVerificationCode(): Observable<any> {
    return this.http.post(API.PREFIX + API.GET_TRANSACTION_VERIFICATION_CODE, {
      sendType: 'phone',
    });
  }

  public verifyDocument(payload: IdDocumentPayload): Observable<any> {
    return this.http
      .post(API.PREFIX + API.VERIFY_DOCUMENT, payload)
      .pipe(map((res: ResponseInfo) => res.result), retry(3),);
  }

  public queryInteracResult(orderId: string): Observable<any> {
    return this.http
      .post(API.PREFIX + API.QUERY_INTERAC_RESULT, { orderId })
      .pipe(map((res: ResponseInfo) => res.result));
  }

  public submitTransaction(): Observable<any> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(this.transaction));
    return this.http
      .post(API.PREFIX + API.SUBMIT_TRANSACTION, formData, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map((res: any) => res.result), map((res) => {
        return res;
      }));
  }

  public getTransactionDetails(txnId: string): Observable<TransactionDetail[]> {
    return this.http
      .get(API.PREFIX + API.GET_TRANSACTION_DETAILS + `?txnId=${txnId}`)
      .pipe(map((res: any) => res.result));
  }

  public getRecent2Transactions() {
    return this.http.get(API.PREFIX + API.GET_RECENT_2_TRANSACTIONS).pipe(map((res: ResponseInfo) => {
      this.recent2Recipients$.next(res.result);
      return res.result;
    }));
  }

  public getRecentAllTransactions() {
    return this.http.get(API.PREFIX + API.GET_RECENT_ALL_TRANSACTIONS).pipe(map((res: ResponseInfo) => res.result));
  }

  public async getInitCountryCurrency(countryCurrencyList: CountryCurrency[], currencyPreference: string): Promise<{ countryCurrency: CountryCurrency, currencyItem: CurrencyItem }> {
    let foundCountryCurrency: CountryCurrency, foundCurrency: string;
    const localSaved = (await Preferences.get({ key: 'lastCountry' })).value;
    if (localSaved) {
      const savedValueArray = localSaved.split('|');
      const isoCode = savedValueArray[0];
      foundCurrency = savedValueArray[1];
      foundCountryCurrency = countryCurrencyList.find(item => item.isoCode === isoCode);
    } else {
      foundCurrency = currencyPreference;
      if (!!foundCurrency) {
        foundCountryCurrency = countryCurrencyList.find((item) => item.currency === foundCurrency);
      }
    }

    if (foundCountryCurrency) {
      const foundCurrencyItem = foundCountryCurrency.currencyList2.find(item => item.currency === foundCurrency);
      if (foundCurrencyItem) {
        return { countryCurrency: foundCountryCurrency, currencyItem: foundCurrencyItem };
      }
    } else {
      return { countryCurrency: countryCurrencyList[0], currencyItem: countryCurrencyList[0].currencyList2[0] };
    }
    return null;
  }

  async showSubmitting() {
    const submitting = await this.loadingController.create({
      message: 'Submitting...',
      id: 'submitting'
    });

    return submitting.present();
  }

  dismissSubmitting() {
    return this.loadingController.dismiss(null, null, 'submitting');
  }
}
