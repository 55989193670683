<ion-header>
  <ion-toolbar [style.background-color]="headerBackground"></ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" [scrollY]="false">
  <div class="wrapper" [style.background-color]="background" [ngClass]="position">
    <div class="duration">{{ duration }} s</div>
    @if (!clickable) {
    <img [src]="imgSrc" alt="Promotion">
    } @else {
    @if (internal === 1) {
    <a [routerLink]="link" (click)="dismiss()" [trackClick]="{ event: 'click-init-promo' }">
      <img [src]="imgSrc" alt="Promotion">
    </a>
    } @else {
    <a [href]="link" target="_blank" [trackClick]="{ event: 'click-init-promo' }">
      <img [src]="imgSrc" alt="Promotion">
    </a>
    }
    }
  </div>
</ion-content>