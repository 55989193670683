import { VERIFICATION_TYPE, VERIFY_TYPE } from './auth.model';

export class TransactionInfo {
  sendType: VERIFICATION_TYPE;
  verifyCode: string | number;
  inBound: InBound;
  outBound: OutBound;
  fxRate: FxRate;
  doc: IdDocumentPayload;
  quotationId: string;
  direction: 'inBound' | 'outBound';

  constructor(info?: {
    firstname: string;
    lastname: string;
    middlename?: string;
  }) {
    this.sendType = VERIFY_TYPE;
    this.verifyCode = null;
    this.inBound = {
      amount: null,
      transactionType: null,
      currency: 'CAD',
      bankName: null,
      bankAccountNumber: null,
      bankSwift: null,
      designationNumber: null,
      bankInstitutionNumber: null,
      bankBranchNumber: null,
      bankAddress: null,
      bankCity: null,
      bankProvince: null,
      bankPostalCode: null,
      bsbNumber: null,
      inFile: null,
      inFileTC: null,
      promotionId: null,
      referCode: null,
      isRedeemPoint: false,
      accountName: null,
      personLastName: info ? info.lastname : null,
      personMiddleName: info ? info.middlename : null,
      personFirstName: info ? info.firstname : null,
      personEmail: null,
      personBirthday: null,
      personPhone: null,
      personAddress: null,
      personCity: null,
      personProvince: null,
      personCountry: null,
      personPostalCode: null,
      purpose: null,
      savedAccount: true,
      sourceOfFund: null,
      followingCode: null,
    };
    this.outBound = {
      amount: null,
      transactionType: null,
      bsbNumber: null,
      sortCode: null,
      bankSwift: null,
      bankId: null,
      bankName: null,
      bankCountryIsoCode: null,
      bankBranchNumber: null,
      bankAccountNumber: null,
      currency: null,
      personFirstName: null,
      personMiddleName: null,
      personLastName: null,
      personBirthday: null,
      personEmail: null,
      personPhone: null,
      personAddress: null,
      personCity: null,
      personProvince: null,
      personCountry: null,
      personPostalCode: null,
      savedAccount: true,
      msisdn: null,
      ifsCode: null,
    };
    this.fxRate = {
      srcCurrency: null,
      destCurrency: null,
      fxRateUSD: null,
      fxRateMarket: null,
      fxRateMarketUSD: null,
      fxMargin: null,
      fxRateOTT: null,
    };
    this.doc = {
      documentType: null,
      firstName: null,
      middleName: null,
      lastName: null,
      documentFrontImage: null,
      documentBackImage: null,
      livePhoto: null,
      addressApproval: null,
    };
    this.quotationId = null;
  }
}

export interface InBound {
  amount: number;
  transactionType: TransactionType;
  currency: string;
  promotionId: number | string;
  referCode: string;
  isRedeemPoint: boolean;
  savedAccount: boolean;
  bsbNumber: string;
  bankName: string;
  bankAccountNumber: string;
  bankSwift: string;
  designationNumber: string;
  bankInstitutionNumber: string;
  bankBranchNumber: string;
  bankAddress: string;
  bankCity: string;
  bankProvince: string;
  bankPostalCode: string;
  inFile: string;
  inFileTC: string;
  accountName: string;
  personLastName: string;
  personFirstName: string;
  personMiddleName: string;
  personEmail: string;
  personBirthday: string;
  personPhone: string;
  personAddress: string;
  personCity: string;
  personProvince: string;
  personCountry: string;
  personPostalCode: string;
  purpose: string;
  sourceOfFund: string;
  followingCode: string;
}

export interface OutBound {
  amount: number;
  transactionType: ServiceType;
  bankId: string | number;
  bankName: string;
  bankSwift: string;
  bsbNumber: string;
  sortCode: string;
  bankCountryIsoCode: string;
  bankBranchNumber: string;
  bankAccountNumber: string;
  currency: string;
  personFirstName: string;
  personMiddleName: string;
  personLastName: string;
  personBirthday: string;
  personEmail: string;
  personPhone: string;
  personAddress: string;
  personCity: string;
  personProvince: string;
  personCountry: string;
  personPostalCode: string;
  savedAccount: boolean;
  msisdn: string;
  ifsCode: string;
}

export interface FxRate {
  destCurrency: string;
  fxMargin: number;
  fxRateMarket: number;
  fxRateMarketUSD: number;
  fxRateOTT: number;
  fxRateUSD: number;
  srcCurrency: string;
}

export interface Payer {
  beneficiaryKYC: string;
  creditPartyIdentifier: string;
  countryIsoCode: string;
  countryName: string;
  countryNameCN: string;
  countryStatus: string;
  currency: string;
  name: string;
  payerId: string | number;
  receiverIDRequried: boolean;
  senderIDRequried: boolean;
  senderKYC: string;
  services: Array<PayerService>;
  transactionDays: number;
}

export interface PayerService {
  payerId: string;
  serviceId: string;
  serviceName: string;
}

export interface TransactionHistory {
  inAmount: number;
  outAmount: number;
  inCurrency: string;
  outCurrency: string;
  status: string;
  orderId: string;
  transactionTime: string | Date;
  goToMessage: boolean;
  isCancel: boolean;
  isUpdate: boolean;
  isViewEtransfer: boolean;
  missedItems: Array<any>;
}

export class Recipient {
  accountType: number | string;
  address: string;
  addressCountry: string;
  bankAccountNumber: string;
  bankHolder: string;
  bankId: number | string;
  bankName: string;
  birth: string;
  birthCountry: string;
  branchNumber: string;
  cId: string;
  city: string;
  currency: string;
  email: string;
  firstname: string;
  gender: string;
  id: number | string;
  idCountry: string;
  idDeliveryDate: string;
  idExpirationDate: string;
  idNumber: string;
  idType: number | string;
  lastname: string;
  middlename: string;
  msisdn: string;
  nationality: string;
  nativename: string;
  occupation: string;
  phone: string;
  postalCode: string;
  province: string;
  serviceType: ServiceType;
  swift: string;
  bsbNumber: string;
  sortCode: string;
  ifsCode: string;

  constructor() {
    this.accountType = null;
    this.address = null;
    this.addressCountry = null;
    this.bankAccountNumber = null;
    this.bankHolder = null;
    this.bankId = null;
    this.bankName = null;
    this.birth = null;
    this.birthCountry = null;
    this.branchNumber = null;
    this.cId = null;
    this.city = null;
    this.currency = null;
    this.email = null;
    this.firstname = null;
    this.gender = null;
    this.id = null;
    this.idCountry = null;
    this.idDeliveryDate = null;
    this.idExpirationDate = null;
    this.idNumber = null;
    this.idType = null;
    this.lastname = null;
    this.middlename = null;
    this.msisdn = null;
    this.nationality = null;
    this.nativename = null;
    this.occupation = null;
    this.phone = null;
    this.postalCode = null;
    this.province = null;
    this.serviceType = null;
    this.swift = null;
    this.bsbNumber = null;
    this.ifsCode = null;
  }
}

export class Sender {
  accountType: number | string;
  address: string;
  addressCountry: string;
  bankHolder: string;
  birth: string;
  birthCountry: string;
  cId: string;
  city: string;
  currency: string;
  email: string;
  firstname: string;
  gender: string;
  id: number | string;
  idCountry: string;
  idDeliveryDate: string;
  idExpirationDate: string;
  idNumber: string;
  idType: number | string;
  lastname: string;
  middlename: string;
  msisdn: string;
  nationality: string;
  nativename: string;
  occupation: string;
  phone: string;
  postalCode: string;
  province: string;
  serviceType: ServiceType;
  swift: string;

  bankName: string;
  branchNumber: string;
  bankAddress: string;
  bankId: number | string;
  bankAccountNumber: string;
  bankCity: string;
  bankInstitutionNumber: string;
  bankPostalcode: string;
  bankProvince: string;
  bankSwift: string;
  designationNumber: string;

  constructor() {
    this.accountType = null;
    this.address = null;
    this.addressCountry = null;
    this.bankAccountNumber = null;
    this.bankHolder = null;
    this.bankId = null;
    this.bankAddress = null;
    this.bankName = null;
    this.bankCity = null;
    this.bankInstitutionNumber = null;
    this.bankPostalcode = null;
    this.bankProvince = null;
    this.bankSwift = null;
    this.designationNumber = null;
    this.birth = null;
    this.birthCountry = null;
    this.branchNumber = null;
    this.cId = null;
    this.city = null;
    this.currency = null;
    this.email = null;
    this.firstname = null;
    this.gender = null;
    this.id = null;
    this.idCountry = null;
    this.idDeliveryDate = null;
    this.idExpirationDate = null;
    this.idNumber = null;
    this.idType = null;
    this.lastname = null;
    this.middlename = null;
    this.msisdn = null;
    this.nationality = null;
    this.nativename = null;
    this.occupation = null;
    this.phone = null;
    this.postalCode = null;
    this.province = null;
    this.serviceType = null;
    this.swift = null;
  }
}

export type ServiceType = 'BankAccount' | 'MobileWallet' | 'CashPickup';
export type TransactionType = 'ETransfer' | 'ETransfer8' | 'ETransfer88';

export interface Point {
  cId: string;
  consumerPoint: number;
  createTime: string | Date;
  direction: 1 | -1;
  id: number;
  orderId: string;
  point: number;
  type: string;
}

export interface TransactionMessage {
  title: string;
  body: string;
  actionTime: string | Date;
  type: string;
  direction: number;
  id: number | string;
  detail: string;
  readCount: number;
  showingDetail?: boolean;
}

export interface CapturedId {
  required: boolean;
  type: IdType;
  frontPhoto: string;
  backPhoto?: string;
  selfiePhoto: string;
  addressApproval?: string;
  signImage?: string;
}

export type IdType =
  | 'DrivingLicence'
  | 'ResidencePermit'
  | 'IdentityCard'
  | 'Passport';

export interface IdDocumentPayload {
  documentType: IdType;
  firstName: string;
  middleName: string;
  lastName: string;
  documentFrontImage: string;
  documentBackImage: string;
  livePhoto: string;
  addressApproval?: string;
  employed?: string;
  employer?: string;
  occupation?: string;
  unemployedDesc?: string;
  industry?: string;
  signImage?: string;
  countryCode?: string;
}

export interface CheckFeeResponse {
  doc: any;
  fee: {
    inFee: number;
    inPayCurreny: string;
    outFee: number;
    outPayCurreny: string;
    pointAmount: number;
    points: number;
  };
  fxRate: FxRate;
  inBound: InBound;
  outBound: OutBound;
  quotationId: string;
  sendType: string;
  followingCode: string;
}

export interface TransactionDetail {
  action: string;
  actionTime: string;
  copyText: string;
  displayStatus: string;
  deliveryTurnAround: string;
  estimateReachDate: string;
  fee: number;
  goToMessage: boolean;
  inAmount: number;
  inCurrency: string;
  isCancel: boolean;
  isUpdate: boolean;
  isViewEtransfer: boolean;
  lang: string;
  link: string;
  missedItems: any[];
  orderId: string;
  outAmount: number;
  outCurrency: string;
  payeeName: string;
  receiveType: 'BankAccount' | 'MobileWallet' | 'CashPickup';
  status: string;
  transactionTime: string;
}

export interface RecentTransaction {
  inBound: {
    amount: number;
    currency: string;
    bankBranchNumber: string;
    bankCountry: string;
    bankInstitutionNumber: string;
    identityType: string;
    personCountry: string;
    purpose: string;
    realRedeemAmount: number;
    realRedeemPoint: number;
    redeem: boolean;
    redeemPoint: number;
    sourceOfFund: string;
  };
  outBound: {
    amount: number;
    bankAccountNumber: string;
    bankCountry: string;
    bankCountryIsoCode: string;
    bankId: string;
    bankInstitutionNumber: string;
    bankName: string;
    currency: string;
    identityType: string;
    msisdn: string;
    personAddress: string;
    personCity: string;
    personCountry: string;
    personFirstName: string;
    personLastName: string;
    personMiddleName: string;
    personPhone: string;
    personProvince: string;
    realRedeemAmount: number;
    realRedeemPoint: number;
    redeem: boolean;
    redeemPoint: number;
    transactionType: ServiceType;
    bsbNumber: string;
    ifsCode: string;
    sortCode: string;
  };
}
