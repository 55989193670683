export const VERIFY_TYPE: 'email' | 'phone' = 'phone';

export type VERIFICATION_TYPE = 'email' | 'phone';

export interface LoginPayload {
    userkey: string;
    platform: string;
    password: string;
    usertype: string;
}

export interface SignupPayload {
    phone: string;
    verifytype: VERIFICATION_TYPE;
    code: string;
    password: string;
    usertype: string;
    email: string;
    firstname: string;
    middlename?: string;
    lastname: string;
    referralcode: string;
}

export interface ForgetPasswordPayload {
    destination: string;
    sendType: VERIFICATION_TYPE;
    password?: string;
    verifyCode: string;
}

export interface ForgetPinPayload {
    destination: string;
    sendType: VERIFICATION_TYPE;
    pin: string;
    verifyCode: string;
}
