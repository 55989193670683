import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-special-referral-offer-hint',
  templateUrl: './special-referral-offer-hint.page.html',
  styleUrls: ['./special-referral-offer-hint.page.scss'],
})
export class SpecialReferralOfferHintPage implements OnInit {

  constructor(private navController: NavController, private modalController: ModalController,) { }

  ngOnInit() {
  }

  send() {
    this.modalController.dismiss();
    this.navController.navigateRoot('tabs/transaction/step-amount');
  }

}
