import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription, timer } from 'rxjs';
import { IonHeader, IonToolbar, IonContent } from "@ionic/angular/standalone";
import { RouterLink } from '@angular/router';
import { TrackClickDirective } from 'src/app/core/directives/track-click.directive';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-init-promo',
  templateUrl: './init-promo.page.html',
  styleUrls: ['./init-promo.page.scss'],
  standalone: true,
  imports: [IonContent, IonToolbar, IonHeader, RouterLink, TrackClickDirective, NgClass]
})
export class InitPromoPage implements OnInit, OnDestroy {

  constructor(private modalController: ModalController,) { }

  @Input() duration: number = 3;
  @Input() background: string = '#ffffff';
  @Input() headerBackground: string = '#04297C';
  @Input() imgSrc: string = '';
  @Input() link: string = '';
  @Input() position: 'center' | 'flex-start' | 'flex-end' = 'flex-start';
  @Input() internal: number = 1;
  @Input() clickable: boolean = false;
  subscription = new Subscription();

  ngOnInit() {
    if (this.duration > 0) {
      this.subscription.add(timer(1000, 1000).subscribe(() => {
        if (this.duration === 0) {
          this.subscription.unsubscribe();
          this.modalController.dismiss(null, null, 'promotion-modal');
        }
        if (this.duration > 0) {
          this.duration = this.duration - 1;
        }
      }));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
