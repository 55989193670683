<ion-content [scrollY]="false">
  <ion-icon name="close-outline" (click)="dismiss()"></ion-icon>
  <img src="assets/images/arts/img-g-card.png" alt="">
  @if (isCN) {
  <img id="text" src="assets/images/arts/img-new-year-text.png" alt="">
  <img id="btn-red" src="assets/images/arts/img-new-year-button-red-envelop.png" alt="" (click)="toRed()">
  <img id="btn-card" src="assets/images/arts/img-new-year-button-card.png" alt="" (click)="toCard()">
  } @else {
  <img id="text-en" src="assets/images/arts/img-new-year-text-en.png" alt="">
  <img id="btn-red-en" src="assets/images/arts/img-new-year-button-red-envelop-en.png" alt="" (click)="toRed()">
  <img id="btn-card-en" src="assets/images/arts/img-new-year-button-card-en.png" alt="" (click)="toCard()">
  }
</ion-content>