import { inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { from, Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEY } from '../constants/storage.constant';
import { Platform } from '@ionic/angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const platform = inject(Platform);
    const appName = platform.is('capacitor') ? 'ottremit' : 'ottremit-webapp';
    // don't modify the request if sending slack messages
    if (request.url === environment.slackMsg) {
      return next.handle(request);
    }

    // handle resource download through get method
    if (request.url.startsWith('https://ottremit.com')) {
      return next.handle(request);
    }

    const token$ = from(Preferences.get({ key: STORAGE_KEY.TOKEN })) as Observable<{ value: string | null }>;
    const lang$ = from(Preferences.get({ key: STORAGE_KEY.LANGUAGE })) as Observable<{ value: string | null }>;

    return forkJoin([token$, lang$]).pipe(
      switchMap(([token, lang]) => {
        let clone;
        if (!!token && token.value) {
          clone = request.clone({
            setHeaders: {
              Authorization: `${token.value}`,
              appName,
              appLang: (lang && lang.value) || 'en',
            },
          });
        } else {
          clone = request.clone({
            setHeaders: {
              appName,
              appLang: (lang && lang.value) || 'en',
            },
          });
        }
        return next.handle(clone);
      })
    );
  }
}
