import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpResponseBase,
} from '@angular/common/http';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { SlackMessagingService } from '../services/slack-messaging.service';
import { InAppMessageService } from '../services/in-app-message.service';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEY } from '../constants/storage.constant';

/**
 * 默认HTTP拦截器，其注册细节见 `app.module.ts`
 */
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private inAppMessageService: InAppMessageService,
    private slackMessagingService: SlackMessagingService,
  ) { }

  subscription = new Subscription();
  subscriptionCount = 0;

  private handleData(
    response: HttpResponseBase,
    request: HttpRequest<any>
  ): Observable<any> {
    switch (response.status) {
      case 200:
        break;
      case 401:
        Preferences.clear();
        console.log(response);
        if (response instanceof HttpErrorResponse) {
          if (response.error.result && response.error.result.error) {
            switch (response.error.result.error) {
              case 'error.incorrect.username.or.password':
                this.inAppMessageService.simpleToast(response.error.result.error);
                break;
            }
          }
        }
        return throwError(response);
        break;
      case 403:
        this.inAppMessageService.simpleToast('error.403');
        return throwError(response);
        break;
      case 0:
        // this.inAppMessageService.simpleToast('error.request.cancelled');
        return throwError(response);
        break;
      default:
        if (response instanceof HttpErrorResponse) {
          this.sendDebugMessage(response, request);
          if (response.error.result && response.error.result.error) {
            switch (response.error.result.error) {
              case 'error.submit.too.often':
                break;
              case 'error.transaction.has.been.flagged':
                break;
              case 'error.duplicate.submit':
                break;
              default:
                this.inAppMessageService.simpleToast(response.error.result.error);
                break;
            }
          }
          return throwError(response);
        }
        break;
    }
    return of(response);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    if (
      !url.startsWith('https://') &&
      !url.startsWith('http://') &&
      !url.startsWith('assets/')
    ) {
      url = environment.API_URL + url;
    }

    const newReq = req.clone({
      url,
      headers: req.headers.set('Accept', 'application/json'),
    });

    return next.handle(newReq).pipe(
      mergeMap((event: any) => {
        // 统一对请求response处理
        if (event instanceof HttpResponseBase) {
          return this.handleData(event, newReq);
        }
        // 若一切都正常，则后续操作
        return of(event);
      }),
      catchError((err: HttpErrorResponse) => this.handleData(err, newReq))
    );
  }

  /**
   * Send a debug message to slack message web hook
   */
  sendDebugMessage(response: HttpResponseBase, request: HttpRequest<any>) {
    if (
      !environment.production &&
      request.urlWithParams !== environment.slackMsg
    ) {
      this.slackMessagingService.sendErrorMsg(request.urlWithParams, [
        {
          author_name: '',
          color: 'danger',
          title: 'Debugging',
          text:
            '========== Body ==========' +
            JSON.stringify(request.body) +
            '\n' +
            '========== Response ==========' +
            '\n' +
            JSON.stringify(response),
        },
      ]);
    }
  }
}
