import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { TransactionService } from '../services/transaction.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionGuard implements CanActivate {
  constructor(
    private transactionService: TransactionService,
    private navController: NavController
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.transactionService.transaction) {
      return true;
    }
    this.navController.navigateRoot('/tabs/home', {
      animationDirection: 'forward',
    });
    return false;
  }
}
