import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { API } from '../constants/api.constant';
import { CustomHttpClientService } from './custom-http-client.service';
import { CheckedInDays, Perk, PointStatus, Redeemable } from '../models/app.model';

@Injectable({
  providedIn: 'root',
})
export class PointsService {
  constructor(private http: CustomHttpClientService) { }

  public refreshPoints$ = new BehaviorSubject<boolean>(false);

  public currentPoints$ = new BehaviorSubject<number>(0);

  public currentPointStatus$ = new BehaviorSubject<PointStatus>(null);

  public checkedInDays$ = new BehaviorSubject<Array<CheckedInDays>>([]);

  public redeemables$ = new BehaviorSubject<Array<Redeemable>>([]);

  public myRedeemedCards$ = new BehaviorSubject<Array<any>>([]);

  public resetForLoggedOut() {
    this.currentPoints$.next(0);
    this.checkedInDays$.next([]);
    this.currentPointStatus$.next(null);
    this.myRedeemedCards$.next([]);
  }

  public filterRedeemCardList(list: Array<Redeemable>, isLoggedIn = true) {
    return list.filter(card => card.pointType != 6);
  }

  public getMyCheckInDates(): Observable<Array<CheckedInDays>> {
    return this.http.get(API.PREFIX + API.GET_DAILY_CHECK_IN_DAYS).pipe(map((res: any) => {
      this.checkedInDays$.next(res.result.signDates);
      return res.result.signDates;
    }));
  }

  public dailyCheckIn(): Observable<any> {
    return this.http.post(API.PREFIX + API.DAILY_CHECK_IN, null).pipe(map((res: any) => res.result));
  }

  public getMyPoints(noLoading?: boolean): Observable<PointStatus> {
    let header = { headers: new HttpHeaders() };
    if (noLoading) {
      header = {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      }
    }
    // without redeemList
    return this.http.get(API.PREFIX + API.GET_MY_CURRENT_POINTS, header).pipe(map((res: any) => {
      this.currentPointStatus$.next(res.result);
      this.currentPoints$.next(res.result.currentPoint);
      return res.result;
    }));
  }

  getRedeemables(): Observable<PointStatus> {
    // with redeemList
    return this.http.get(API.PREFIX + API.GET_MY_POINTS, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    }).pipe(map((res: any) => {
      const filtered = this.filterRedeemCardList(res.result.redeemList);
      if (this.redeemables$.getValue().length === 0) {
        this.redeemables$.next(filtered);
      }
      this.currentPointStatus$.next(res.result);
      this.currentPoints$.next(res.result.currentPoint);
      return res.result;
    }));
  }

  getPublicAvailRedeemables(): Observable<PointStatus> {
    return this.http.get(API.PREFIX + API.GET_AVAIL_REDEEMABLES, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    }).pipe(map((res: any) => {
      const filtered = this.filterRedeemCardList(res.result.redeemList, false);
      if (this.redeemables$.getValue().length === 0) {
        this.redeemables$.next(filtered);
      }
      return res.result;
    }));
  }

  getActivatedCards(): Observable<Array<any>> {
    return this.http.get(API.PREFIX + API.GET_ACTIVATED_CARDS, {
      headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
    }).pipe(map((res: any) => {
      const cards = res.result.sort((a, b) => b.id - a.id);
      this.myRedeemedCards$.next(cards);
      return cards;
    }));
  }

  getMyCardDetail(card): Observable<any> {
    return this.http.get(API.PREFIX + API.GET_MY_CARD_DETAIL + card.cardNumber).pipe(map((res: any) => res.result));
  }

  getMyCardBarcode(card): Observable<any> {
    return this.http.get(API.PREFIX + API.GET_CARD_BARCODE + card.barCode).pipe(map((res: any) => res.result));
  }

  redeemCard(card): Observable<any> {
    return this.http.post(API.PREFIX + API.REDEEM_CARDS, card).pipe(map((res: any) => res.result));
  }

  deleteCard(card): Observable<any> {
    return this.http.put(API.PREFIX + API.DELETE_CARD, { cardNumber: card.cardNumber });
  }

  getPerks(): Observable<Perk[]> {
    return this.http.get(API.PREFIX + API.GET_PERKS_LIST, {
    }).pipe(map((res: any) => res.result));
  }

  getPublicPerks(): Observable<Perk[]> {
    return this.http.get(API.PREFIX + API.GET_PUBLIC_PERKS_LIST, {
    }).pipe(map((res: any) => res.result));
  }

  submitPerk(perk: Perk): Observable<any> {
    return this.http.post(API.PREFIX + API.SUBMIT_PERK, { perks_type: perk.perksType });
  }
}
