import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IonContent, IonIcon } from "@ionic/angular/standalone";

@Component({
  selector: 'app-chinese-new-year',
  templateUrl: './chinese-new-year.page.html',
  styleUrls: ['./chinese-new-year.page.scss'],
  standalone: true,
  imports: [IonIcon, IonContent,]
})
export class ChineseNewYearPage implements OnInit {

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private translateService: TranslateService,
  ) { }

  isCN = this.translateService.currentLang === 'cn';
  isEN = this.translateService.currentLang === 'en';

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss(null, null, 'new-year-modal');
  }

  toRed() {
    this.dismiss();
    this.navController.navigateForward('choose-envelope');
  }

  toCard() {
    this.dismiss();
    this.navController.navigateForward('choose-greeting-card');
  }

}
