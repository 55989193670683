import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { Observable, from, of } from 'rxjs';
import { API } from '../constants/api.constant';
import { CustomHttpClientService } from './custom-http-client.service';
import { Preferences } from '@capacitor/preferences';

export const DEVICE_ID_KEY = 'device_id';

import {
  PushNotifications,
  Token,
  PushNotificationSchema,
  ActionPerformed,
  PermissionStatus,
} from '@capacitor/push-notifications';
import { environment } from 'src/environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { InAppMessageService } from './in-app-message.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './app.service';
import { NativeSettings, IOSSettings, AndroidSettings } from 'capacitor-native-settings';
import { HttpHeaders } from '@angular/common/http';
import { AnalyticsService } from './analytics.service';
import { STORAGE_KEY } from '../constants/storage.constant';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private router: Router,
    private http: CustomHttpClientService,
    private alertController: AlertController,
    private inAppMessageService: InAppMessageService,
    private translateService: TranslateService,
    private appService: AppService,
    private platform: Platform,
    private analyticsService: AnalyticsService,
    private toastController: ToastController,
  ) { }

  private subscribed = false;
  private machineId: string;

  public initPush(machineId?: string) {
    if (machineId) {
      this.machineId = machineId;
    }
    if (this.platform.is('capacitor')) {
      this.registerPush();
    }
  }

  public subscribePushPublic(deviceId: string): Observable<any> {
    if (this.subscribed) {
      return of(false);
    }
    const payload = {
      app_version: environment.version.ios,
      device_id: deviceId,
      machine_id: this.machineId
    };
    return this.http
      .post(API.PREFIX + API.SUBSCRIBE_PUSH_NOTIFICATION_PUBLIC, payload, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      });
  }

  public subscribePush(deviceId: string): Observable<any> {
    if (this.subscribed) {
      return of(false);
    }
    const payload = {
      app_version: environment.version.ios,
      device_id: deviceId,
      machine_id: this.machineId
    };
    return this.http
      .post(API.PREFIX + API.SUBSCRIBE_PUSH_NOTIFICATION, payload, {
        headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
      })
      .pipe(map(() => (this.subscribed = true)));
  }

  public unsubscribePush(): Observable<any> {
    if (!this.subscribed) {
      return of(false);
    }
    return from(Preferences.get({ key: DEVICE_ID_KEY })).pipe(
      switchMap(({ value }) => {
        const payload = {
          app_version: environment.version.ios,
          device_id: value,
          machine_id: this.machineId
        };
        return this.http
          .post(API.PREFIX + API.UNSUBSCRIBE_PUSH_NOTIFICATION, payload, {
            headers: new HttpHeaders({ ignoreLoading: 'ignoreLoading' }),
          })
          .pipe(map(() => (this.subscribed = false)));
      }),
      switchMap(() => from(Preferences.remove({ key: DEVICE_ID_KEY })))
    );
  }

  private async showPermissionAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('common.hint'),
      message: this.translateService.instant('message.open.settings'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translateService.instant('common.cancel'),
        },
        {
          text: this.translateService.instant('common.openSettings'),
          handler: () => {
            if (this.platform.is('ios')) {
              NativeSettings.openIOS({ option: IOSSettings.App });
            }
            if (this.platform.is('android')) {
              NativeSettings.openAndroid({ option: AndroidSettings.Settings });
            }
          },
        },
      ],
    });

    alert.present();
  }

  private registerPush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions()
      .then((status: PermissionStatus) => {
        if (status.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          this.analyticsService.logEvent({ eventName: 'push-notification-granted' });
          PushNotifications.register();
        } else if (status.receive === 'denied') {
          this.analyticsService.logEvent({ eventName: 'push-notification-denied' });
        }
      })
      .catch((error) => {
        this.appService.traceAction({ action: 'error', desc: 'push notification request permissions error: ' + JSON.stringify(error) });
      });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: Token) => {
      const authTokenResult = await Preferences.get({ key: STORAGE_KEY.TOKEN });
      if (authTokenResult && authTokenResult.value) {
        this.subscribePush(token.value).subscribe(
          () => Preferences.set({ key: DEVICE_ID_KEY, value: token.value })
        );
      } else {
        this.subscribePushPublic(token.value).subscribe(
          () => Preferences.set({ key: DEVICE_ID_KEY, value: token.value })
        );
      }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      this.appService.traceAction({ action: 'error', desc: 'push notification registration error: ' + JSON.stringify(error) });
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        if (this.platform.is('android')) {
          this.showNotificationReminder();
          // this.inAppMessageService.simpleToast('message.new.message', true, 'top');
        }
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        this.analyticsService.logEvent({ eventName: 'click_notification' });
        const data = notification.notification.data;
        if (data.routeInApp) {
          if (!data.requiredAuth) {
            this.router.navigateByUrl(`${data.routeInApp}`);
          } else {
            const authTokenResult = await Preferences.get({ key: STORAGE_KEY.TOKEN });
            if (authTokenResult && authTokenResult.value) {
              this.router.navigateByUrl(`${data.routeInApp}`);
            }
          }
        }
      }
    );
  }

  private async showNotificationReminder() {
    const toast = await this.toastController.create({
      message: this.translateService.instant('message.new.message'),
      position: 'top',
      duration: 3000,
      buttons: [
        {
          side: 'end',
          role: 'navigate',
          icon: 'arrow-forward-outline'
        }
      ]
    });

    toast.onDidDismiss().then(result => {
      if (result && result.role === 'navigate') {
        this.router.navigateByUrl(`/my-messages`);
      }
    });

    return await toast.present();
  }
}
