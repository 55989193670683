import { APP_INITIALIZER, ErrorHandler, Injector, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { AppService } from './core/services/app.service';
import { AppInterceptor } from './core/interceptors/app.interceptor';
import { AppServiceFactory } from './core/factories/app.factory';
import { createTranslateLoader, TranslationFactory } from './core/factories/translate.factory';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { register } from 'swiper/element/bundle';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideEnvironmentNgxMask } from 'ngx-mask';

register();

// #region APP_INITIALIZER
const APP_INITIALIZERS: Provider[] = [
  AppService,
  {
    provide: APP_INITIALIZER,
    useFactory: AppServiceFactory,
    deps: [AppService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: TranslationFactory,
    deps: [TranslateService, Injector],
    multi: true,
  },
];
// #endregion

// #region APP_INITIALIZER
const APP_INTERCEPTORS: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
];
// #endregion

const SENTRY: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => { },
    deps: [Sentry.TraceService],
    multi: true,
  },
  { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
  { provide: Sentry.TraceService, deps: [Router] }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'ios',
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  providers: [
    ...APP_INITIALIZERS,
    ...APP_INTERCEPTORS,
    provideEnvironmentNgxMask(),
    provideIonicAngular({ mode: 'ios', innerHTMLTemplatesEnabled: true }),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ...SENTRY,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
