import { Injectable } from '@angular/core';
import { UrlTree, CanLoad, CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(
    private authService: AuthService,
    private navController: NavController
  ) {}
  canLoad(): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated.pipe(
      filter((value) => value !== null),
      take(1),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        } else {
          this.navController.navigateForward('/login');
          return false;
        }
      })
    );
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.isAuthenticated.pipe(
      filter((value) => value !== null),
      take(1),
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        } else {
          this.navController.navigateForward('/login');
          return false;
        }
      })
    );
  }
}
